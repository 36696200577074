import React    from "react"
import ParaHead from "./para_head"
import ParaImg  from "./myImage4"

const Paragraph = (props) => (
  <div className="row">
    <ParaHead txt={props.txt} />
    <ParaImg my_img={props.my_img} my_txt={props.my_txt} />
  </div>
)
export default Paragraph;
