import React   		from "react"
import Layout  	  from "../components/layout"
import Para       from "../components/paragraph"

const Schlaf = <p>
							Separates gemütliches Schlafzimmer.<br />
							Der Hausherr hat es sich nicht nehmen lassen die rustikalen Betten selber zu bauen.
							<br />
							Die Betten sind 100x 200cm und 90x 200cm.
							Ein geräumiger Kleiderschrank mit Spiegeltür runden den Ruheraum ab. <br /></p>
const Wohn = <p>Die Gesamtgröße der Wohnung beträgt ca. 42m².
	<br />
Austattung: Flachbildfernseher mit Kabel Digital, Telefonflat und Internet.</p>
const Kueche = <p>Die Einbauküche lässt keine Wünsche offen.
							Die schöne Ausstattung wurde mit sehr viel Liebe zum Detail ausgesucht.
					    Selbst an Kleinigkeiten wurde gedacht.</p>
const Flur = <p>Über einen separaten Eingang führt das Treppenhaus zu Ihrer Ferienwohnung.</p>
const Bad = <p>Das Bad mit großer, begehbarer Dusche (ca.1x 2m), ist mit großem Spiegelschrank
							<br />
							und Handtuchheizung ausgestattet. Ein Föhn ist vorhanden.</p>
const Sessel = <p>
							Das große Wohnzimmer mit 2 Massagesesseln animieren zum Entspannen.
							Eine kleine DVD Sammlung lädt zum benutzen des großen Flachbildschirms ein.
							Stereo Radio, DVD Spieler und  Surroundanlage sind selbstverständlich.
							PC-und Telefonanschluss sind vorhanden.
							</p>

const Rooms = () => (
	<Layout head_gif = "wohnung_bhb.png">
		<Para txt="Wohnzimmer"    my_img="wohnzimmer.jpg"    my_txt={Wohn}/>
		<Para txt="Fernsehsessel" my_img="fernsehsessel.jpg" my_txt={Sessel}/>
		<Para txt="Schlafzimmer"  my_img="schlafzimmer.jpg"  my_txt={Schlaf}/>
		<Para txt="Küche" 				 my_img="kueche.jpg" 		    my_txt={Kueche}/>
		<Para txt="Badezimmer"    my_img="badezimmer.png" 	  my_txt={Bad}/>
		 <Para txt="Treppenhaus"   my_img="treppenhaus.jpg"   my_txt={Flur}/>
</Layout>
)
export default Rooms;
